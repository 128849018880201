// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../node_modules/@pp-frontend/accrual-cards/accrualsTemplate/fonts/Golos-Text_Regular.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../node_modules/@pp-frontend/accrual-cards/accrualsTemplate/fonts/Golos-Text_Regular.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../node_modules/@pp-frontend/accrual-cards/accrualsTemplate/fonts/Golos-Text_Medium.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../node_modules/@pp-frontend/accrual-cards/accrualsTemplate/fonts/Golos-Text_Medium.woff");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../node_modules/@pp-frontend/accrual-cards/accrualsTemplate/fonts/Golos-Text_DemiBold.woff2");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../../node_modules/@pp-frontend/accrual-cards/accrualsTemplate/fonts/Golos-Text_DemiBold.woff");
var ___CSS_LOADER_URL_IMPORT_6___ = require("../../node_modules/@pp-frontend/accrual-cards/accrualsTemplate/fonts/GolosText-Bold.woff2");
var ___CSS_LOADER_URL_IMPORT_7___ = require("../../node_modules/@pp-frontend/accrual-cards/accrualsTemplate/fonts/GolosText-Bold.woff");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media screen and (max-width:1279px){.gl-fl-hide[data-v-e8f63578]{display:none!important}}@media screen and (min-width:992px){.gl-md-show[data-v-e8f63578]{display:none!important}}@media screen and (max-width:991px){.gl-md-hide[data-v-e8f63578]{display:none!important}}@media screen and (min-width:768px){.gl-sm-show[data-v-e8f63578]{display:none!important}}@media screen and (max-width:767px){.gl-sm-hide[data-v-e8f63578]{display:none!important}}.project-color[data-v-e8f63578]{color:#008559}.project-background[data-v-e8f63578]{background-color:#008559!important}.project-border[data-v-e8f63578]{border-color:#008559!important}.project-cards-background[data-v-e8f63578]{background-color:#fff!important}.project-svg[data-v-e8f63578]{fill:#008559}.project-svg-stroke[data-v-e8f63578]{stroke:#008559}@font-face{font-display:swap;font-family:\"Golos\";font-style:normal;font-weight:400;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\")}@font-face{font-display:swap;font-family:\"Golos\";font-style:normal;font-weight:500;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\")}@font-face{font-display:swap;font-family:\"Golos\";font-style:normal;font-weight:600;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"woff\")}@font-face{font-display:swap;font-family:\"Golos\";font-style:normal;font-weight:700;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format(\"woff\")}*[data-v-e8f63578]{font-family:\"Golos\",Arial,sans-serif}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
